import * as React from 'react';
import {
    bodyStyle,
    mobileBodyStyle,
    contentContainerStyle,
    mobileContentContainerStyle,
    contentDivStyle
} from "./styles";
import {useSpring, animated} from 'react-spring';
import {Transition, SwitchTransition} from "react-transition-group";
import {useLocation} from "@reach/router";

const ANIMATION_DURATION = 800;

const ContentContainer = ({ children, breakpoints}) => {
    const location = useLocation();
    return (
        <main style={breakpoints.sm ? mobileBodyStyle : bodyStyle}>
            <SwitchTransition>
                <Transition key={location.pathname} timeout={ANIMATION_DURATION}>
                    { state => (
                        <AnimatedContent breakpoints={breakpoints} isIn={(state === "entering") || (state === "entered")}>
                        {children}
                        </AnimatedContent>
                    )}
                </Transition>
            </SwitchTransition>
        </main>
    )
}

const AnimatedContent = ({children, breakpoints, isIn}) => {
    const springProps = useSpring({
        height: isIn ? "100%" : "0%",
    })
    const containerStyle = breakpoints.sm ? mobileContentContainerStyle : contentContainerStyle;
    return (
        <animated.div style={{ ...containerStyle, height: springProps.height }}>
            <div style={contentDivStyle}>
                {children}
            </div>
        </animated.div>
    )
}


const wrapContent = (Component) => (Component)


export { ContentContainer, wrapContent}