import "@fontsource/lato/300.css"


const color1 = "#2E4057";
const color2 = "#F9FBF2";
const color3 = "#AFCBFF";
const color4 = "#466286";
// const color5 = "#A25D3B";

const heading1 = {
    fontSize: "2.5em",
    lineHeight: "1.125em"
}
const heading2 = {
    fontSize: "2em",
    lineHeight: "1.25em"
}
const heading3 = {
    fontSize: "1.5em",
    lineHeight: "1.25em"
}
const heading4 = {
    fontSize: "1.125em",
    lineHeight: "1.222222em"
}
const bodyText = {
    fontSize: "1em",
    lineHeight: "1.375em"
}
const mobileHeading1 = {
    fontSize: "2em",
    lineHeight: "1.125em"
}
const mobileHeading2 = {
    fontSize: "1.625em",
    lineHeight: "1.15384615em"
}
const mobileHeading3 = {
    fontSize: "1.375em",
    lineHeight: "1.36363636em"
}
const mobileHeading4 = {
    fontSize: "1em",
    lineHeight: "1.25em"
}
const mobileBodyText = {
    fontSize: "0.9em",
    lineHeight: "1.25em"
}


const pageContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    backgroundColor: color1,
    color: color2,
    fontFamily: ["Lato", "sans-serif"]
}

const mobilePageContainer = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    fontFamily: ["Lato", "sans-serif"],
    color: color1,
    backgroundColor: color2
}

const navbarContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    height:"100vh",
    padding: "24px",
    boxSizing: "border-box",
    width: "33vw"
}

const mobileNavbarContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "20px 20px",
    backgroundColor: color1,
    color: color2,
}

const dividerStyle = {
    borderLeft: "2px solid ".concat(color2),
    height: "75vh",
    marginLeft: "-1px"
}

const bodyStyle = {
    width: "67vw",
    padding: "60px 60px",
    boxSizing: "border-box",
    height: "100vh",
    display: "flex",
    alignItems: "center"
}

const mobileBodyStyle = {
    padding: "30px 30px",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    maxHeight: "88vh"

}

const nameStyle = {
    paddingBottom: "60px",
    ...heading1
}

const mobileNameStyle = {
    ...mobileHeading3
}

const tinyNameStyle = {
    ...mobileHeading4
}

const linkListStyle = {
    display: "flex",
    flexDirection: "column"
}

const linkStyle = {
    margin: "12px",
    textAlign: "center",
    border: "1px solid ".concat(color3),
    padding: "12px 36px",
    textDecoration: "none",
    color: color3
}

const mobileLinkListStyle = {
    display: "flex",
    alignItems: "center",
}


const mobileLinkStyle = {
    margin: "0px 8px",
    color: color3
}

const tinyLinkStyle = {
    margin: "0px 4px",
    color: color3,
    fontSize: "0.9em",
    lineHeight: "1em"
}

const contentContainerStyle = {
    height: "100%",
    width: "100%",
    backgroundColor: color2,
    color: color1,
    boxShadow: "0px 0px 8px 0px " + color2,
    overflowY: "scroll"
}

const mobileContentContainerStyle = {
    height: "100%",
    width: "100%",
    backgroundColor: color4,
    color: color2,
    boxShadow: "0px 0px 8px 0px " + color1,
    overflowY: "scroll"
}

const contentDivStyle = {
    height: "100%",
    width: "100%",
}

const getContainerStyle = (breakpoints) => {
    const baseStyle = {

        maxHeight: "100%",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
    }
    return {
        ...baseStyle,
        padding: breakpoints.sm ? "16px" : "24px"
    }
}

const mobileURLColor = {
    color: color3
}

const urlColor = {
    color: color4
}



export {
    color1, color2, color3, color4,
    pageContainer, mobilePageContainer,
    navbarContainer, mobileNavbarContainer,
    dividerStyle, bodyStyle, mobileBodyStyle,
    nameStyle, mobileNameStyle, tinyNameStyle, linkListStyle, linkStyle, mobileLinkStyle, mobileLinkListStyle, tinyLinkStyle,
    heading1, heading2, heading3, heading4, bodyText,
    mobileHeading1, mobileHeading2, mobileHeading3, mobileHeading4, mobileBodyText,
    contentContainerStyle, mobileContentContainerStyle, contentDivStyle, urlColor, mobileURLColor,
    getContainerStyle
}