import * as React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import "./reset.css";
import Navbar from "./navbar";
import {dividerStyle, mobilePageContainer, pageContainer} from "./styles";
import {ContentContainer} from "./content";

const pages = [
    { path: "/", name: "home"},
    { path: "/research", name: "research"},
    { path: "/teaching", name: "teaching"}
]

const Layout = ({ children }) => {
    const breakpoints = useBreakpoint()
    return (
        <React.Fragment>
            <title>nicholas wu</title>
            <div style={breakpoints.sm ? mobilePageContainer : pageContainer}>
                <Navbar breakpoints={breakpoints} pages={pages} />
                <div style={breakpoints.sm ? null : dividerStyle }/>
                <ContentContainer breakpoints={breakpoints}>
                    {children}
                </ContentContainer>
            </div>
        </React.Fragment>
    )
}
export default Layout