import * as React from "react";
import {Link} from "gatsby";
import {
    color1,
    color2, color3,
    linkListStyle, linkStyle,
    mobileLinkListStyle, mobileLinkStyle,
    mobileNameStyle,
    mobileNavbarContainer,
    nameStyle,
    navbarContainer, tinyLinkStyle, tinyNameStyle
} from "./styles";
import {useSpring, animated} from "react-spring";

const AnimatedLink = animated(Link)

const NavbarLink = ({ path, name, breakpoints}) => {
    const [hovered, setHover] = React.useState(false)
    const transitionStyle = useSpring({
        backgroundColor: hovered ? breakpoints.sm ? color2 + "00" : color2 + "FF"  : color2 + "00",
        color: hovered ? breakpoints.sm ? color2 : color1 : color3
    })
    const mouseEnter = () => {
        setHover(true)
    }
    const animatedLinkStyle = breakpoints.xs ? tinyLinkStyle : breakpoints.sm ? mobileLinkStyle : linkStyle
    return (
       <AnimatedLink to={path} key={name}
             style={{ ...animatedLinkStyle, ...transitionStyle}}
             onMouseEnter={mouseEnter} onMouseLeave={()=>(setHover(false))}>
           {name}
       </AnimatedLink>
   )
}


const Navbar = ({ breakpoints, pages }) => {
    return (
        <nav style={breakpoints.sm ? mobileNavbarContainer : navbarContainer}>
            <h1 style={breakpoints.xs ? tinyNameStyle : breakpoints.sm ? mobileNameStyle : nameStyle}>nicholas wu</h1>
            <div style={breakpoints.sm ? mobileLinkListStyle : linkListStyle}>
                { pages.map(({ path, name }) => (
                    <NavbarLink path={path} name={name} key={path} breakpoints={breakpoints}/>
                ))}
            </div>
        </nav>
    )
}

export default Navbar